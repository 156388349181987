// src/routes/AppRouter.js
import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import MainLayout from '../views/MainLayout';
import LoginPage from '../views/LoginPage';
import Dashboard from '../views/Dashboard';
import CourseList from '../views/CourseList';
import PrivateRoute from './PrivateRoute';

const AppRouter = () => {
  // Check if the user is already logged in
  const token = localStorage.getItem('token');
  
  return (
    <Router>
      <Routes>
        {/* If not logged in, redirect to login */}
        <Route path="/" element={token ? <Navigate to="/dashboard" /> : <Navigate to="/login" />} />
        
        {/* Public Route for Login */}
        <Route path="/login" element={<LoginPage />} />
        
        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={<PrivateRoute component={Dashboard} />}
        />
        <Route
          path="/courses"
          element={<PrivateRoute component={CourseList} />}
        />
        {/* Add other routes here */}
      </Routes>
    </Router>
  );
};

export default AppRouter;
