// src/views/LoginPage.js
import React, { useState } from 'react';
import { Form, Input, Button, Alert } from 'antd';
import { useNavigate } from 'react-router-dom'; // Change import to useNavigate

const LoginPage = () => {
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate(); // Use useNavigate instead of useHistory

  const onFinish = async (values) => {
    const { email, password } = values;
    setLoading(true);
    setError(null);

    try {
      // Call the loginUser API
      const response = await fetch('https://us-central1-classconnect-7ecb8.cloudfunctions.net/loginUser', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || 'Login failed');
      }

      // Check user role
      if (data.role !== 'institution-admin' && data.role !== 'developer-admin') {
        setError('Access denied. Only institution admins and developer admins can log in.');
        return;
      }

      // Store the token in local storage or context
      localStorage.setItem('token', data.token);

      // Redirect to the dashboard
      navigate('/'); // Use navigate instead of history.push
    } catch (error) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ maxWidth: 300, margin: 'auto', padding: '100px 0' }}>
      {error && <Alert message={error} type="error" showIcon />}
      <Form
        name="login"
        onFinish={onFinish}
        layout="vertical"
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[{ required: true, message: 'Please input your email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[{ required: true, message: 'Please input your password!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Login
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default LoginPage;
